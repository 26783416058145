/*@media (prefers-color-scheme: light) {
    html {
      --theme-color-accent: hsl(9, 77%, 31%);
      --theme-color-light:  #fafafa;
      --theme-color-dark:   #141414;

      --theme-background-1: #F5F5F5;
      --theme-color-1:      #8d8d8d;

      --theme-background-2: #E0E0E0;
      --theme-color-2:      #424242;

      --theme-background-3: #9E9E9E;
      --theme-color-3:      #212121;

      --theme-transparent: rgba(36, 36, 36, 0.0266);
      --theme-shadow-color: rgba(36, 36, 36, 0.266);
    }
}

@media (prefers-color-scheme: dark) {*/
    html {
        --theme-color-accent: #fff;
        --theme-color-light:  #efefef;
        --theme-color-dark:   #1a1a1a;

        --theme-background-3: #4e4e4e;
        --theme-color-3:      #dddddd;
  
        --theme-background-2: #3f3f3f;
        --theme-color-2:      #e0e0e0;
  
        --theme-background-1: #2f2f2f;
        --theme-color-1:      #bdbdbd;

        --theme-transparent: rgba(246, 246, 246, 0.052);
        --theme-shadow-color: rgba(219, 219, 219, 0.226);
      }
/*}*/



html {
    --theme-shadow: 0px 21px 18px -13px var(--theme-shadow-color);
}


.flex_col {
    display: flex;
    flex-flow: column wrap !important;
    align-items: center !important;
    justify-content: center !important;
}

.flex_row {
    display: flex;
    flex-flow: row wrap !important;
    align-items: center !important;
    justify-content: center !important;
}

.flex_col.flex__spaced, 
.flex_row.flex__spaced {
    justify-content: space-between !important;  
}



.shadow,
.shadow-over:hover {
    box-shadow: var(--theme-shadow);
    -webkit-box-shadow: var(--theme-shadow);
    -moz-box-shadow: var(--theme-shadow);
}

.bigText {
    font-size: 125%;
}



ul.unstyledList,
ol.unstyledList {
    text-align: left;
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    }

ul.unstyledList li,
ol.unstyledList li {
    line-height: 2;
} 

a:hover,
a:focus {
    text-decoration: none;
}


.full-screen-block {
    height: calc(100vh - 80px); /** 80px === header */
    width: 100%;
    padding: 20px;
    margin: 0;
}

@media (min-width: 1400px) {
    .full-screen-block {
        height: calc(100vh - 100px); /** 80px === header  + 20px margin-top*/
    }
}



.hiddenScroll {
    overflow: hidden;
}


.Container {
    max-width: 1380px;
    margin: 0 auto;
}