body {
  margin: 0;
  font-family: 'Alegreya', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: var(--theme-background-1);
  color: var(--theme-color-2);
  font-size: 18px;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.root {
  font-size: calc(10px + 2vmin);
}

h1,
h2,
h3,
h4 {
  font-family: 'Old Standard TT', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
}


.App {
  text-align: left;
  max-width: 1380px;
  margin: 0 auto;
}


a {
  color: rgb(99, 106, 136);
}

a:hover {
  color: rgb(80, 70, 214);
}

a:active {
  color: rgb(40, 40, 161)
}

a:visited {
  color: rgb(114, 69, 133);
}


#screenSizeDisclaimer {
  padding: 15px 5px;
  text-align: center;
  background: red;
  color: #fff;
  margin: 10px auto;
  width: 100%;
  z-index: 999;
  position: relative;
  display: block;
}
/*
spostato sulla home -> <style></style>

@media (min-width: 320px) {
  #screenSizeDisclaimer {
    display: none !important;
    visibility: hidden;
    z-index: -99;
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: -1000px 0 0 0;
  }
}*/