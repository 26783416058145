.lazyload-wrapper,
.image-wrapper {
  background: var(--theme-background-2);
  color: var(--theme-color-2);
  position: relative;
}

.bookPage .lazyload-wrapper::before {
  content: "Caricamento in corso...";
  position: absolute;
  z-index: 1;
  margin: 35% 25%;
}

.lazyload-wrapper img,
.image-wrapper img {
    position: relative;
    z-index: 3;
    background-color: var(--theme-background-1);
}