.Book {
    width: 100%;
    height: 100%;
    max-width: 310px;
    font-size: 14px;
    color: var(--theme-color-2);
    background: var(--theme-transparent);
    padding: 15px;
    text-align: center;
    display: inline-block;
    cursor: zoom-in;
    border-radius: 1px;
}


.Book-cover {
    max-width: 100%;
    display: block;
    margin-bottom: 15px;
    height: 400px;
    min-width: 280px;
    z-index: 4;
    transition: transform .5s ease;
    border-radius: 1px;
}

.Book:hover .Book-cover {
    transform: scale(1.025);
    box-shadow: var(--theme-shadow);
    -webkit-box-shadow: var(--theme-shadow);
    -moz-box-shadow: var(--theme-shadow);
}

.Book-info {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
    background-color: var(--theme-background-2);
    color: var(--theme-color-3);
    border-bottom: 4px solid var(--theme-background-3);
    border-radius: 1px;
}

.Book-info__base {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all .5s ease-in;
}

.Book-info__base p,
.Book-info__base h2 {
    margin: 0;
}
.Book-info__base h2 {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Book-info__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: var(--theme-background-2);
    transition: all .5s ease-out;
    z-index: -2;
}

.Book-info__overlay span {
    opacity: 0;
    font-weight: 600;
    transition: all .5s ease-out .35s;
}

.Book:hover .Book-info__overlay {
    height: 100%;
    z-index: auto;
}

.Book:hover .Book-info__overlay span {
    opacity: 1;
}

.Book:hover .Book-info__base {
    opacity: 0;
}