.Header {
    display: inline-flex !important;
    text-align: left;
    vertical-align: middle;
}

.Header h1 {
    display: none;
}


@media (min-width: 768px) {
    .Header h1 {
        display: inline-block;
        color: var(--theme-color-accent);
        font-weight: 900;
        margin: 0;
    }
    
    .Header h1 a {
        text-decoration: none;
        color: inherit;
    }
}

.Logo {
    max-height: 60px;
    display: inline-block;
    margin-right: 15px;
}

/*@media (prefers-color-scheme: dark) {*/
    .Logo {
      padding: 9.5px 11px;
      border-radius: 50px;
      background-color: rgba(205,205,205,0.65);
    }
/*}*/