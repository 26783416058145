.Navbar {
    width: 100%;
    max-height: 80px;
    /*max-width: 1380px;*/
    padding: 10px 35px;
    color: var(--theme-color-2);
    background: var(--theme-background-2);
    font-size: 18px;
    margin: 0 auto;
}

@media (min-width: 1400px) {
    .Navbar {
        margin-top: 20px;
    }
}

.Menu a {
    color: var(--theme-color-3);
}


.Menu-Wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    max-width: 100vw;
    background: var(--theme-background-3);
    color: var(--theme-color-3);
}

.Menu {
    --padding: 10px;
    width: 100%;
    height: 100%;
    padding: var(--padding);
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
}

@media (min-width: 996px) {
    .Menu {
        --padding: 30px;
    }
}

@media (min-width: 1200px) {
    .Menu {
        --padding: 60px;
    }
}

.Menu-Wrapper.Menu__aperto {
    display: block;
    z-index: 7;
} /*{
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    z-index: 7;
}*/

.Menu-Wrapper.Menu__chiuso {
    display: none;
}

.Menu__blocco {
    display: block;
    width: 100%;
    padding: 20px;
}

@media (min-width: 996px) {
    .Menu-Autori.Menu__blocco {
        width: 30%;
    }
    .Menu-Contatti.Menu__blocco {
        width: 70%;
        border-left: 2px solid var(--theme-color-1);
    }
}


@media (min-width: 1060px) {
    .Menu-Autori.Menu__blocco {
        width: 40%;
    }
    .Menu-Contatti.Menu__blocco {
        width: 60%;
        border-left: 2px solid var(--theme-color-1);
    }
}



@media (min-width: 1200px) {
    .Menu-Autori.Menu__blocco {
        width: 44%;
    }
    .Menu-Contatti.Menu__blocco {
        width: 56%;
        border-left: 2px solid var(--theme-color-1);
    }
}


.Menu-Autori {
    font-size: 24px;
    line-height: 1.8;
    color: var(--theme-color-1);
}

.Menu-Autori h4,
.Menu-Contatti__blocco h4 {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--theme-color-2);
}

.autore {
    transition: all .2s ease;
    color: inherit;
    position: relative;
    text-decoration: none;
    display: block;
    margin: 5px auto;
}
.autore:hover {
    color: var(--theme-color-accent);
}

.autore::after {
    content: '';
    display: block;
    height: 3px;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color-accent);
    transition: all .2s ease;
}

.autore:hover::after {
    width: 120px;
}



.Menu-Contatti {
    display: grid !important;
    grid-template-columns: auto-fill;
    grid-template-rows: auto-fill;
}

@media (min-width: 996px) {
    .Menu-Contatti {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(320px, 1fr));
        grid-template-rows: repeat(2, minmax(320px, 1fr));
    }
    .Menu-Contatti__blocco {
        padding: 20px;
        /*border: 2px solid var(--theme-background-2);*/
    }
}


#Menu-Btn {
    background: var(--theme-background-1);
    color: var(--theme-color-3);
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 800;
    border: none;
    cursor: pointer;
}

.btn-open {
    float: right;
}

.btn-close {
    /*position: absolute;
    top: 20px;
    right: 25px;*/
    z-index: 10;
}



/*#collezione-btn {
    background: var(--theme-background-1);
    color: var(--theme-color-2);
    padding: 5px 10px;
    font-size: 80%;
    text-decoration: none;
}*/