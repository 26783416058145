.Libreria {
    width: 100%;
    margin: 0 auto;
    background-color: var(--theme-background-1);
    color: var(--theme-color-1);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-template-rows: repeat( auto-fill, minmax(525px, 1fr));
    place-items: center;
    column-gap: 9px;
    row-gap: 16px;
  }
  
  @media (min-width: 340px) {
    .Libreria {
      padding: 15px;
    }
  }

  @media (min-width: 996px) {
    .Libreria {
      padding: 25px;
    }
  }
