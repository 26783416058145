.Wrapper--Book {
    max-height: 100%;
    margin: 0 auto;
    position:relative;
    overflow: hidden;
    padding-bottom: 2.4em;
}


/*
@media (min-width: 1100px) {
  .Wrapper--Book::before {
    position: absolute;
    content: "Clicca sull'immagine per sfogliare il libro";
    top: 35%;
    left: 10%;
    text-align: center;
  }
}
*/

.bookPage,
.bookPage .lazyload-wrapper,
.bookPage .image-wrapper {
    height: 100% !important;
    width: fit-content;
    margin: auto;
    background: none;
}

.bookPage .lazyload-wrapper img,
.bookPage .image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}


/**
.bookPage.stf__item.--soft.--right.--simple {
	background-color: rgba(140, 36, 18, 0.4);
}
*/

.Details {
    padding: 15px 30px;
    margin: 40px;
    background: var(--theme-background-2);
    color: var(--theme-color-2);
}

@media (min-width: 620px) {
    .--left {
        float: right;
        text-align: right;
    }
    .--right {
        float: left;
        text-align: left;
    }
}




#pagesRange {
    width: 70%;
    margin: 1em;
    display: inline-block;
    vertical-align: middle;
}

.slider {
    --size: .8em;
    -webkit-appearance: none;
    height: calc(var(--size) / 2);
    background: var(--theme-background-3);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    position: relative;
}

.slider:focus {
    outline: none
  }

.slider:hover {
    opacity: 1;
  }

.slider:hover::-webkit-slider-thumb {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.slider:hover::-moz-range-thumb {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.slider::-webkit-slider-runnable-track {
    border: none;
    border-radius: 3px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--size);
    height: var(--size);
    background: var(--theme-color-light);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    border-color: var(--theme-background-3);
    cursor: pointer;
  }
  
.slider::-moz-range-thumb {
    appearance: none;
    width: var(--size);
    height: var(--size);
    background: var(--theme-color-light);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    border-color: var(--theme-background-3);
    cursor: pointer;
  }







  
#Leggio {
  width: 80%;
  height: 80%;
  margin: 0 auto;
}